import React, { useEffect, useRef, useContext } from "react";
import * as THREE from "three";
import { OBJLoader } from "three-stdlib";
import { TopicContext } from "./TopicContext";

export default function ThreeSketchy() {
  const { topicType } = useContext(TopicContext, true);

  const canvasRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const meshRef = useRef(null);

  useEffect(() => {
    let loader;

    const foundModel =
      topicType === "early"
        ? "https://storage.googleapis.com/purina-virtual-experience/04-advancing-brain-health/models/25_DHA.obj"
        : topicType === "aging"
          ? "https://storage.googleapis.com/purina-virtual-experience/04-advancing-brain-health/models/28_MCT.obj"
          : topicType === "conditions"
            ? "https://storage.googleapis.com/purina-virtual-experience/04-advancing-brain-health/models/35_DogBrain.obj"
            : "https://storage.googleapis.com/purina-virtual-experience/04-advancing-brain-health/models/35_DogBrain.obj";

    const modelColor =
      topicType === "early"
        ? 0xfcc72e
        : topicType === "aging"
          ? 0xae46b9
          : topicType === "conditions"
            ? 0xdd9296
            : 0xffffff;

    const init = () => {
      if (!rendererRef.current) {
        // Scene setup
        sceneRef.current = new THREE.Scene();
        sceneRef.current.background = new THREE.Color(0x03202f); // Set background color to #03202f

        // Camera setup - Adjust FOV and camera position
        const aspectRatio =
          (window.innerWidth * 0.5) / (window.innerHeight * 0.9);
        cameraRef.current = new THREE.PerspectiveCamera(
          60,
          aspectRatio,
          5,
          1000,
        ); // Adjust FOV to 60
        cameraRef.current.position.z = 600; // Move camera further back to accommodate larger models
        cameraRef.current.lookAt(0, 0, 0);

        // Renderer setup
        rendererRef.current = new THREE.WebGLRenderer({ antialias: true });
        rendererRef.current.setSize(
          window.innerWidth * 0.5,
          window.innerHeight * 0.9,
        );
        canvasRef.current.appendChild(rendererRef.current.domElement);
        rendererRef.current.shadowMap.enabled = true; // Enable shadow maps

        // Lighting setup - increased intensity for brighter lighting
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.7); // Increase ambient light intensity
        sceneRef.current.add(ambientLight);

        const pointLight1 = new THREE.PointLight(0xffffff, 2, 1000); // Increase intensity and range
        pointLight1.position.set(-200, 200, 200);
        pointLight1.castShadow = true;
        sceneRef.current.add(pointLight1);

        const pointLight2 = new THREE.PointLight(0xffffff, 2, 1000); // Increase intensity and range
        pointLight2.position.set(200, -200, -200);
        pointLight2.castShadow = true;
        sceneRef.current.add(pointLight2);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 2); // Increased intensity
        directionalLight.position.set(100, 300, 400);
        directionalLight.castShadow = true;
        sceneRef.current.add(directionalLight);
      }

      // Load model (only once)
      if (!meshRef.current) {
        loader = new OBJLoader();
        loader.load(
          foundModel,
          (obj) => {
            const material = new THREE.MeshStandardMaterial({
              color: modelColor,
              roughness: 0.5,
              metalness: 0.2,
            });

            obj.traverse(function (child) {
              if (child instanceof THREE.Mesh) {
                child.material = material;
                child.castShadow = true;
                child.receiveShadow = true;
              }
            });

            // Flip all models upside down (rotate around X-axis by 180 degrees)
            obj.rotation.x = Math.PI * 2; // 360 degrees (already rotated by Math.PI before)

            // Increase scale for larger models
            if (topicType === "early" || topicType === "aging") {
              obj.scale.set(20, 20, 20); // Increase scale for early and aging
            } else if (topicType === "conditions") {
              obj.scale.set(4, 4, 4); // Increase scale for conditions
            }

            sceneRef.current.add(obj);
            meshRef.current = obj;
          },
          undefined,
          (error) => {
            console.error("Error loading model:", error);
          },
        );
      }

      animate();
    };

    const animate = () => {
      requestAnimationFrame(animate);
      if (meshRef.current) {
        meshRef.current.rotation.y += 0.01;
      }
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    };

    // Update aspect ratio on resize
    const handleResize = () => {
      const width = window.innerWidth * 0.5;
      const height = window.innerHeight * 0.9;
      cameraRef.current.aspect = width / height;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(width, height);
    };

    window.addEventListener("resize", handleResize);

    if (canvasRef.current && !rendererRef.current) {
      init();
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      if (rendererRef.current) {
        rendererRef.current.dispose();
      }
    };
  }, [topicType]);

  return <div ref={canvasRef}></div>;
}
